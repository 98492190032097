import React, { useEffect, useLayoutEffect, useState, useRef } from "react"
import gql from "graphql-tag"
import { useLazyQuery, useMutation } from "@apollo/react-hooks"
import { Helmet } from "react-helmet"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import NumberFormat from "react-number-format"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { useMediaPredicate } from "react-media-hook"
import Modal from "react-modal"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import { Section, Container } from "../../../components/grid/grid"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const GET_PHONENUMBER = gql`
  query formWvc2023 {
    formWvc2023 {
      phoneNumber
    }
  }
`

const SUBMIT_REGISTER = gql`
  mutation submit2Wvc2023(
    $fullName: String
    $phoneNumber: String
    $email: String
    $category: String
    $subCategory: String
    $detailCategory: String
  ) {
    submit2Wvc2023(
      input: {
        clientMutationId: ""
        fullName: $fullName
        phoneNumber: $phoneNumber
        email: $email
        category: $category
        subCategory: $subCategory
        detailCategory: $detailCategory
      }
    ) {
      status
      message
    }
  }
`

const RegisterWvc2023Page = (location) =>{
  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        page(id: "wvc2023", idType: URI) {
          title
          featuredImage {
            mediaItemUrl
          }
          seo {
            title
            twitterTitle
            twitterDescription
            metaDesc
            opengraphTitle
            opengraphDescription
            focuskw
          }
        }
      }
    }
  `)

  const [getResult, { data }] = useLazyQuery(GET_PHONENUMBER)
  const [confirmModal, setConfirmModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [sendDataFailed, setSendDataFailed] = useState(false)
  const [btnSubmitLoading, setBtnSubmitLoading] = useState(false)
  const [submitRegister, { loading: submitRegisterLoading }] = useMutation(SUBMIT_REGISTER)
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const wordPress = staticData.wordPress?.page
  const seo = wordPress?.seo
  const featuredImage = wordPress?.featuredImage?.mediaItemUrl
  const currentUrl = process.env.BASE_URL + location.pathname

  const [formValues, setFormValues] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    category: "",
    subCategory: "",
    detailCategory: "",
    tnc: "",
  })

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }

  const handleCloseConfirmModal = () => {{
    setConfirmModal(false)
  }}

  const handleCloseSuccessModal = () => {{
    window.location.href = sendDataFailed ? "/wvc2023/register" : "/wvc2023"
    setSuccessModal(false)
  }}

  const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {
    const phoneNumberHandler = (val, setFieldValue) =>
      setFieldValue("phoneNumber", val)
  
      const [checked, setChecked] = useState(false)
      const [category, setCategory] = useState("")
      const [subCategory, setSubCategory] = useState("")
      const [detailCategory, setDetailCategory] = useState("")
  
      function handleOnChange(){
        setChecked(!checked)
      }

      useEffect(() => {
        if (category !== "") {
          setFieldValue("category", category)
        }

        if (subCategory !== "") {
          setFieldValue("subCategory", subCategory)
        }

        if (detailCategory !== "") {
          setFieldValue("detailCategory", detailCategory)
        }
      }, [])
  
    return (
      <Form className="row pt-4" id="wvc2023form">
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nama</label>
          <Field
            name="name"
            className="form-control px-0"
            placeholder="Nama Anda"
            type="text"
            id="customerName"
          />
          {touched.name && errors.name && (
            <div className="invalid-value d-block">{errors.name}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nomor Handphone</label>
          <NumberFormat
            name="phoneNumber"
            className="form-control px-0"
            placeholder="Nomor Handphone"
            allowEmptyFormatting
            prefix="+62"
            value={values.phoneNumber}
            onValueChange={(val) =>
              phoneNumberHandler(val.formattedValue, setFieldValue)
            }
            id="customerPhone"
          />
          {touched.phoneNumber && errors.phoneNumber && (
            <div className="invalid-value d-block">{errors.phoneNumber}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Email</label>
          <Field
            name="email"
            className="form-control px-0"
            placeholder="Email Anda"
            type="text"
            id="customerEmail"
          />
          {touched.email && errors.email && (
            <div className="invalid-value d-block">{errors.email}</div>
          )}
        </div>
        <div className="row col-12 col-md-12 pb-3 pt-3">
          <div className="col-12 col-md-12 pb-2">
            <label className="text-muted my-0">Kategori</label>
          </div>
          <Field
            name="category"
            render={({ field }) => {
              return (
                <div className="d-flex mb-3 mb-lg-4">
                  <label className="radio-wrapper mr-4 mr-md-6">
                    Writing
                    <input
                      {...field}
                      id="writing"
                      type="radio"
                      checked={field.value === "writing"}
                      name="category"
                      value="writing"
                      onClick={() => setCategory("writing")}
                    />
                    <span className="radio-button" />
                  </label>
                  <label className="radio-wrapper">
                    Video
                    <input
                      {...field}
                      id="video"
                      type="radio"
                      checked={field.value === "video"}
                      name="category"
                      value="video"
                      onClick={() => setCategory("video")}
                    />
                    <span className="radio-button" />
                  </label>
                </div>
              )
            }}
          />
          {/* <div className="col-12 col-xs-6 col-sm-6 col-md-6">
            <Field 
              type="checkbox" 
              name="category"
              value="writing"
              id="writing"
              // checked={category === "writing"}
              // onClick={() => setCategory("writing")}
              checked = {categoryRef.current = "writing"}
              ref = {categoryRef}
              onClick={() => categoryRef.current = "writing"}
            />
            &nbsp;<span>Writing</span>
          </div>
          <div className="col-12 col-xs-6 col-sm-6 col-md-6">
            <Field
              type="checkbox" 
              name="category"
              value="video"
              id="video"
              // checked={category === "video"}
              // onClick={() => setCategory("video")}
              checked = {categoryRef.current = "video"}
              ref = {categoryRef}
              onClick={() => categoryRef.current = "video"}
            />
            &nbsp;<span>Video</span>
          </div> */}
        </div>
        {touched.category && errors.category && (
          <div className="col-12 col-md-12">
            <div className="invalid-value d-block">{errors.category}</div>
          </div>
        )}
        {/* <div className="col-12 col-md-12 d-none">
          <Field
            name="category1"
            className="form-control px-0"
            placeholder=""
            type="text"
            id="category"
            value={category}
          />
        </div> */}
        <div className={`row col-12 col-md-12 pb-3 pt-3 ${category === "writing" ? "d-flex" : "d-none"}`} id="w-sub-category">
          <div className="col-12 col-md-12 pb-2">
            <label className="text-muted my-0">Sub Kategori</label>
          </div>
          <Field
            name="subCategory"
            render={({ field }) => {
              return (
                <div className="d-flex mb-3 mb-lg-4">
                  <label className="radio-wrapper mr-4 mr-md-6">
                    Jurnalis
                    <input
                      {...field}
                      id="jurnalis"
                      type="radio"
                      checked={field.value === "jurnalis"}
                      name="subCategory"
                      value="jurnalis"
                      onClick={() => setSubCategory("jurnalis")}
                    />
                    <span className="radio-button" />
                  </label>
                  <label className="radio-wrapper">
                    Umum
                    <input
                      {...field}
                      id="w-umum"
                      type="radio"
                      checked={field.value === "w-umum"}
                      name="subCategory"
                      value="w-umum"
                      onClick={() => setSubCategory("w-umum")}
                    />
                    <span className="radio-button" />
                  </label>
                </div>
              )
            }}
          />
          {/* <div className="col-12 col-xs-6 col-sm-6 col-md-6">
            <Field 
              type="checkbox" 
              name="subCategory" 
              value="jurnalis"
              id="jurnalis"
              // checked={subCategory === "jurnalis"}
              // onClick={() => setSubCategory("jurnalis")}
              checked = {subCategoryRef.current = "jurnalis"}
              ref = {subCategoryRef}
              onClick={() => subCategoryRef.current = "jurnalis"}
            />
            &nbsp;<span>Jurnalis</span>
          </div>
          <div className="col-12 col-xs-6 col-sm-6 col-md-6">
            <Field 
              type="checkbox" 
              name="subCategory" 
              value="umum"
              id="w-umum"
              // checked={subCategory === "w-umum"}
              // onClick={() => setSubCategory("w-umum")}
              checked = {subCategoryRef.current = "w-umum"}
              ref = {subCategoryRef}
              onClick={() => subCategoryRef.current = "w-umum"}
            />
            &nbsp;<span>Umum</span>
          </div> */}
        </div>
        <div className={`row col-12 col-md-12 pb-3 pt-3 ${category === "video" ? "d-flex" : "d-none"}`} id="v-sub-category">
          <div className="col-12 col-md-12 pb-2">
            <label className="text-muted my-0">Sub Kategori</label>
          </div>
          <Field
            name="subCategory"
            render={({ field }) => {
              return (
                <div className="d-flex mb-3 mb-lg-4">
                  <label className="radio-wrapper mr-4 mr-md-6">
                    Media
                    <input
                      {...field}
                      id="media"
                      type="radio"
                      checked={field.value === "media"}
                      name="subCategory"
                      value="media"
                      onClick={() => setSubCategory("media")}
                    />
                    <span className="radio-button" />
                  </label>
                  <label className="radio-wrapper">
                    Umum
                    <input
                      {...field}
                      id="v-umum"
                      type="radio"
                      checked={field.value === "v-umum"}
                      name="subCategory"
                      value="v-umum"
                      onClick={() => setSubCategory("v-umum")}
                    />
                    <span className="radio-button" />
                  </label>
                </div>
              )
            }}
          />
          {/* <div className="col-12 col-xs-6 col-sm-6 col-md-6">
            <Field 
              type="checkbox" 
              name="subCategory" 
              value="media"
              id="media"
              checked={subCategory === "media"}
              onClick={() => setSubCategory("media")}
            />
            &nbsp;<span>Media</span>
          </div>
          <div className="col-12 col-xs-6 col-sm-6 col-md-6">
          <Field 
              type="checkbox" 
              name="subCategory" 
              value="umum"
              id="v-umum"
              checked={subCategory === "v-umum"}
              onClick={() => setSubCategory("v-umum")}
            />
            &nbsp;<span>Umum</span>
          </div> */}
        </div>
        {touched.subCategory && errors.subCategory && (
          <div className="col-12 col-md-12">
            <div className="invalid-value d-block">{errors.subCategory}</div>
          </div>
        )}
        {/* <div className="col-12 col-md-12 d-none">
          <Field
            name="subCategory1"
            className="form-control px-0"
            placeholder=""
            type="text"
            id="subCategory"
            value={subCategory}
          />
        </div> */}
        <div className={`row col-12 col-md-12 pb-3 pt-3 ${subCategory === "jurnalis" || subCategory === "w-umum" ? "d-block" : "d-none"}`} id="w-detail-category">
          <div className="col-12 col-md-12 pb-2">
            <label className="text-muted my-0">Kategori Detail</label>
          </div>
          <Field
            name="detailCategory"
            render={({ field }) => {
              return (
                <div className={`${subCategory === "jurnalis" ? "d-flex" : "d-none"} mb-3 mb-lg-4`}>
                  <label className="radio-wrapper mr-4 mr-md-6">
                    Print(Majalah dan Koran)
                    <input
                      {...field}
                      id="print"
                      type="radio"
                      checked={field.value === "print"}
                      name="detailCategory"
                      value="print"
                      onClick={() => setDetailCategory("print")}
                    />
                    <span className="radio-button" />
                  </label>
                  <label className="radio-wrapper">
                    Online (Media Sosial)
                    <input
                      {...field}
                      id="online-news"
                      type="radio"
                      checked={field.value === "online-news"}
                      name="detailCategory"
                      value="online-news"
                      onClick={() => setDetailCategory("online-news")}
                    />
                    <span className="radio-button" />
                  </label>
                </div>
              )
            }}
          />
          <Field
            name="detailCategory"
            render={({ field }) => {
              return (
                <div className={`${subCategory === "w-umum" ? "d-flex" : "d-none"} mb-3 mb-lg-4`}>
                  <label className="radio-wrapper mr-4 mr-md-6">
                    Blogger
                    <input
                      {...field}
                      id="blogger"
                      type="radio"
                      checked={field.value === "blogger"}
                      name="detailCategory"
                      value="blogger"
                      onClick={() => setDetailCategory("blogger")}
                    />
                    <span className="radio-button" />
                  </label>
                </div>
              )
            }}
          />
          {/* <div className={`col-12 col-xs-6 col-sm-6 col-md-6 pb-3 ${subCategory === "jurnalis" ? "d-block" : "d-none"}`}>
            <Field 
              type="checkbox" 
              name="detailCategory" 
              value="print"
              id="print"
              checked={detailCategory === "print"}
              onClick={() => setDetailCategory("print")}
            />
            &nbsp;<span>Print(Majalah dan Koran)</span>
          </div>
          <div className={`col-12 col-xs-6 col-sm-6 col-md-6 pb-3 ${subCategory === "jurnalis" ? "d-block" : "d-none"}`}>
            <Field 
              type="checkbox" 
              name="detailCategory" 
              value="online-news"
              id="online-news"
              checked={detailCategory === "online-news"}
              onClick={() => setDetailCategory("online-news")}
            />
            &nbsp;<span>Online News</span>
          </div> */}

          {/* <div className={`col-12 col-xs-6 col-sm-6 col-md-6 ${subCategory === "w-umum" ? "d-block" : "d-none"}`}>
          <Field 
              type="checkbox" 
              name="detailCategory" 
              value="blogger"
              id="blogger"
              checked={detailCategory === "blogger"}
              onClick={() => setDetailCategory("blogger")}
            />
            &nbsp;<span>Blogger</span>
          </div> */}
        </div>
        <div className={`row col-12 col-md-12 pb-3 pt-3 ${subCategory === "media" || subCategory === "v-umum" ? "d-block" : "d-none"}`} id="v-detail-category">
          <div className="col-12 col-md-12 pb-2">
            <label className="text-muted my-0">Kategori Detail</label>
          </div>
          <Field
            name="detailCategory"
            render={({ field }) => {
              return (
                <div className={`${subCategory === "media" ? "d-flex" : "d-none"} mb-3 mb-lg-4`}>
                  <label className="radio-wrapper mr-4 mr-md-6">
                  Online (Media Sosial)
                    <input
                      {...field}
                      id="online-socmed"
                      type="radio"
                      checked={field.value === "online-socmed"}
                      name="detailCategory"
                      value="online-socmed"
                      onClick={() => setDetailCategory("online-socmed")}
                    />
                    <span className="radio-button" />
                  </label>
                </div>
              )
            }}
          />
          <Field
            name="detailCategory"
            render={({ field }) => {
              return (
                <div className={`${subCategory === "v-umum" ? "d-flex" : "d-none"} mb-3 mb-lg-4`}>
                  <label className="radio-wrapper mr-4 mr-md-6">
                  Content Creator: Instagram/Tiktok
                    <input
                      {...field}
                      id="content-creator"
                      type="radio"
                      checked={field.value === "content-creator"}
                      name="detailCategory"
                      value="content-creator"
                      onClick={() => setDetailCategory("content-creator")}
                    />
                    <span className="radio-button" />
                  </label>
                </div>
              )
            }}
          />
          {/* <div className={`col-12 col-xs-6 col-sm-6 col-md-6 ${subCategory === "media" ? "d-block" : "d-none"}`}>
            <Field 
              type="checkbox" 
              name="detailCategory" 
              value="online"
              id="online-socmed"
              checked={detailCategory === "online-socmed"}
              onClick={() => setDetailCategory("online-socmed")}
            />
            &nbsp;<span>Online (Media Sosial)</span>
          </div>
          <div className={`col-12 col-xs-6 col-sm-6 col-md-6 ${subCategory === "v-umum" ? "d-block" : "d-none"}`}>
          <Field 
              type="checkbox" 
              name="detailCategory" 
              value="content-creator"
              id="content-creator"
              checked={detailCategory === "content-creator"}
              onClick={() => setDetailCategory("content-creator")}
            />
            &nbsp;<span>Content Creator: Instagram/Tiktok</span>
          </div> */}
        </div>
        {touched.detailCategory && errors.detailCategory && (
          <div className="col-12 col-md-12">
            <div className="invalid-value d-block">{errors.detailCategory}</div>
          </div>
        )}
        {/* <div className="col-12 col-md-12 d-none">
          <Field
            name="detailCategory1"
            className="form-control px-0"
            placeholder=""
            type="text"
            id="detailCategory"
            value={detailCategory}
          />
        </div> */}
        <div className="col-12 col-md-12 pb-3 pt-3">
          <Field 
            type="checkbox" 
            name="tnc" 
            value="tnc"
            id="tnc"
            onClick={handleOnChange}
          />
          &nbsp;<span className="tnc-wvc">Saya setuju dengan syarat & ketentuan yang berlaku.</span><span className="red-star" style={{color:"#ab0204"}}>*</span>
        </div>
        {touched.tnc && errors.tnc && (
          <div className="invalid-value d-block">{errors.tnc}</div>
        )}
        <div className="col-12 pt-5">
          <button className="btn btn-primary w-100 w-md-25">
            {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
          </button>
        </div>
      </Form>
    )
  }

  // const [formValues, setFormValues] = useState({
  //   name: "",
  //   phoneNumber: "",
  //   email: "",
  //   category: "",
  //   subCategory: "",
  //   detailCategory: "",
  //   tnc: "",
  // })

  // const sendRegisterData = () => {
  //   const formatPhoneNumber = formValues.phoneNumber.split("+").join("")
  //   setBtnSubmitLoading(true)

  //   submitRegister({
  //     variables: {
  //       fullName: formValues.name,
  //       phoneNumber: formatPhoneNumber,
  //       email: formValues.email,
  //       category: formValues.category.toString(),
  //       subCategory: formValues.subCategory.toString(),
  //       detailCategory: formValues.detailCategory.toString(),
  //     }
  //   }).then(res => {
  //     if (res.data.submit2Wvc2023.status === "success") {
  //       setSendDataFailed(false)
  //       setConfirmModal(false)
  //       setFormValues({ 
  //         name: "",
  //         phoneNumber: "",
  //         email: "",
  //         category: "",
  //         subCategory: "",
  //         detailCategory: "",
  //         // tnc: "",
  //       })
  //       setSuccessModal(true)
  //       setBtnSubmitLoading(false)
  //     } else {
  //       setSendDataFailed(true)
  //       setSuccessModal(true)
  //       setBtnSubmitLoading(false)
  //     }
  //   })

  // }

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ name, phoneNumber, email, category, subCategory, detailCategory, tnc,}) {
      return {
        name: formValues.name || name,
        phoneNumber: formValues.phoneNumber || phoneNumber,
        email: formValues.email || email,
        category: formValues.category || category,
        subCategory: formValues.subCategory || subCategory,
        detailCategory: formValues.detailCategory || detailCategory,
        tnc: formValues.tnc || tnc,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      email: Yup.string().required("Email is required"),
      category: Yup.string().required("Category is required"),
      subCategory: Yup.string().required("Sub Category is required"),
      detailCategory: Yup.string().required("Detail Category is required"),
      tnc: Yup.string().required("Disclaimer is required"),
    }),
    handleSubmit(values) {
      setFormValues(values)

      let dataList = data && data.formWvc2023

      for (let i = 0; i < dataList.length; i++) {
        if (dataList[i].phoneNumber == values.phoneNumber.split("+").join("")) {
          console.log(">>1")
          alert("Maaf! Anda tidak bisa mengikuti program WVC 2023 kembali.")
          return false
        }
      }

      setConfirmModal(true)

    },
  })(LeadForm)

  const sendRegisterData = () => {
    console.log("LOG-1")
    const formatPhoneNumber = formValues.phoneNumber.split("+").join("")
    setBtnSubmitLoading(true)
    console.log("LOG-2")
    submitRegister({
      variables: {
        fullName: formValues.name,
        phoneNumber: formatPhoneNumber,
        email: formValues.email,
        category: formValues.category.toString(),
        subCategory: formValues.subCategory.toString(),
        detailCategory: formValues.detailCategory.toString(),
      },
    }).then(res => {
      console.log("LOG-3")
      if (res.data.submit2Wvc2023.status === "success") {
        console.log("LOG-4")
        setSendDataFailed(false)
        setConfirmModal(false)
        setFormValues({ 
          name: "",
          phoneNumber: "",
          email: "",
          category: "",
          subCategory: "",
          detailCategory: "",
          // tnc: "",
        })
        setSuccessModal(true)
        setBtnSubmitLoading(false)
      } else {
        console.log("LOG-5")
        setSendDataFailed(true)
        setSuccessModal(true)
        setBtnSubmitLoading(false)
      }
    })

  }

  useEffect(() => {
    if(location.uri === "/wvc2023/register"){
      window.location.href = "/wvc2023"
    }
  }, [])

  useEffect(() => {
    getResult()
  }, [])

  useEffect(() => {
    const inputCheckbox = document.querySelectorAll('input[type=checkbox]')

    inputCheckbox.forEach(item => {
      item.addEventListener('click', function(){
        const thisBox = this
        if (thisBox.checked) {
          const groupBox = "input:checkbox[name='" + this.attr("name") + "']"
          groupBox.checked = false
          thisBox.checked = true
        }else{
          thisBox.checked = false
        }
      })
    })

    for (let i = 0; i < inputCheckbox.length; i++) {
      if (inputCheckbox[i].checked) {
        const groupChecked = document.querySelector('input[type=checkbox]').getAttribute('name')
        groupChecked.checked = false
        inputCheckbox[i].checked = true
        document.getelementby
      } else {
        inputCheckbox[i].checked = false
      }
      
    }
  }, [])

  useEffect(() => {
    let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
    let loginBtnSection = document.getElementById("desktopNav").querySelector(".nav-item.nav-desktop.ml-3")
    let loginBtnSectionMobile = document.getElementById("navbarMobile").querySelector(".navbar-toggle-wrapper.fixed-top .btn-login")
    let langSection = document.querySelector("#lang-wrapper")
      
    if(resizeScreen){
      document.getElementsByClassName("logo-secondary")[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    }else{
      document.getElementsByClassName("logo-secondary")[0].style.display = `none`
      document.getElementsByClassName("logo-main")[0].style.display = `block`
    }

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#000"
    }

    loginBtnSectionMobile.classList.add('d-none')
    loginBtnSection.classList.add('d-none')
    langSection.classList.remove('d-flex')
    langSection.classList.add('d-none')
    
  }, [])

  return(
    <Layout location={location} currentLocation={location.uri} isWvc={true}>
      <SEO 
        title={`Writing and Video Competition (WVC) 2023`}
        fbTitle={`Writing and Video Competition (WVC) 2023`}
        twitterTitle={`Writing and Video Competition (WVC) 2023`}
        description={`Writing and Video Competition 2023 by Sinar Mas Land. Nantikan puluhan hadiah dan hiburan dari Sinar Mas Land hanya di event ini!.`}
        fbDescription={`Writing and Video Competition 2023 by Sinar Mas Land. Nantikan puluhan hadiah dan hiburan dari Sinar Mas Land hanya di event ini!.`}
        twitterDescription={`Writing and Video Competition 2023 by Sinar Mas Land. Nantikan puluhan hadiah dan hiburan dari Sinar Mas Land hanya di event ini!.`}
        url={currentUrl}
        img={featuredImage}
        fbImg={featuredImage}
        twitterImg={featuredImage}
      />
      <Helmet>
        <meta 
          name="keywords" 
          content={seo?.focuskw ? seo?.focuskw : "Writing and Video Competition (WVC) 2023"}
        ></meta>
        <meta 
          name="copyright" 
          content="2023 | Sinar Mas Land"
        ></meta>
      </Helmet>
      <Section className="main-register-wvc bg-gray-50" id="register-wvc-form">
        <Container>
          <EnhancedLeadForm />
        </Container>
      </Section>
      <Modal
        isOpen={confirmModal}
        onRequestClose={handleCloseConfirmModal}
        style={customStyles}
        contentLabel="campaign modal"
        overlayClassName="modal-overlay-general"
      >
        <div className="modal-popup-otp modal-body text-center">
          <div className="container row py-5 mw-100 m-0">
            <div className="col-md-12 mb-5">
              <h2>Peringatan!</h2>
              <p className="m-0">
                Apakah data Anda sudah benar?
              </p>
            </div>
            {btnSubmitLoading ? (
              <>
                <div className="col-md-12">
                  <span>Please wait <i className="fa fa-spinner fa-spin"></i></span>
                </div>
              </>
            ) : (
              <>
                <div className="col-xs-6 col-sm-6 col-md-6">
                  <button 
                    className="btn btn-primary"
                    onClick={handleCloseConfirmModal}
                  >
                    No
                  </button>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-6">
                  <button 
                    className="btn btn-primary"
                    onClick={sendRegisterData}
                  >
                    Yes
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={successModal}
        onRequestClose={handleCloseSuccessModal}
        style={customStyles}
        contentLabel="campaign modal"
        overlayClassName="modal-overlay-general"
      >
        <div className="modal-popup-otp modal-body text-center">
          <div className="container py-5 mw-100 mw-md-75">
            <h2>{sendDataFailed ? "Sorry !" : "Thank You!"}</h2>
            <p className="m-0">
              {sendDataFailed ? "Silahkan ulangi kembali pengisian data Anda" : "Data Anda telah tersimpan."}
            </p>
            <button 
              className="btn btn-primary"
              onClick={handleCloseSuccessModal}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  )
}
export default RegisterWvc2023Page